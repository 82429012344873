import 'src/application'

import 'bootstrap'
import 'bootstrap-select'
import toastr from 'toastr'
import jquery from 'jquery'
import Rails from "@rails/ujs"

window.toastr = toastr
window.$ = jquery
window.Rails = Rails
Rails.start()

require.context('images', true)
